import enLabels from './en.json'
import skLabels from './sk.json'

import enUrls from './en-urls.json'
import skUrls from './sk-urls.json'

import badgeBarSK from '../images/badge-bars-sk.svg'
import badgeCuiSK from '../images/badge-cuisine-sk.svg'
import badgeGroSK from '../images/badge-grocery-sk.svg'
import badgeMarSK from '../images/badge-market-sk.svg'
import badgeBarEN from '../images/badge-bars-en.svg'
import badgeCuiEN from '../images/badge-cuisine-en.svg'
import badgeGroEN from '../images/badge-grocery-en.svg'
import badgeMarEN from '../images/badge-market-en.svg'

export const labels = {
    en_US: enLabels,
    sk_SK: skLabels,
}
export const localeUrls = {
    en_US: enUrls,
    sk_SK: skUrls,
}

export const days = (lng) => [labels[lng]['SUNDAY'], labels[lng]['MONDAY'], labels[lng]['TUESDAY'], labels[lng]['WEDNESDAY'], 
    labels[lng]['THUSRDAY'], labels[lng]['FRIDAY'], labels[lng]['SATURDAY']];

export const badgesLocale = {
    en_US: {
        'bars': badgeBarEN,
        'cuis': badgeCuiEN,
        'groc': badgeGroEN,
        'mark': badgeMarEN
    },
    sk_SK: {
        'bars': badgeBarSK,
        'cuis': badgeCuiSK,
        'groc': badgeGroSK,
        'mark': badgeMarSK
    }
}