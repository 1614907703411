import React from "react";
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { labels, localeUrls } from '../langs/langs'

import ArrowLink from "./ArrowLink";

import logo from '../images/logo.svg';
import fbIcon from "../images/fb-w.svg"
import inIcon from "../images/ig-w.svg"
import bLogo from "../images/brandly.svg"
import dLogo from "../images/db-logo.svg"

const Footer = ({ localeID }) => {
    return (
        <>
            <footer>
                <div className='content fw'>
                    <div className="ohCon">
                        <img id='footerLogo' width='50' src={logo} alt='logo'/>
                        <span><strong>Fresh Market</strong><br/>
                        Rožňavská 1A<br/>
                        831 04 Bratislava<br/>
                        Tel: +354 587 9999<br/>
                        info@freshmarket.sk<br/></span>
                        <p>
                            <a href="https://www.facebook.com/FarmarskaTrznica/" target="_blank"><img src={fbIcon}/></a>
                            <a href="https://www.instagram.com/freshmarketba/" target="_blank"><img src={inIcon}/></a>
                        </p>
                    </div>
                    <div>
                        <h5>{labels[localeID]["SITEMAP"]}</h5>
                        <Link to={localeUrls[localeID]["HOME"]}>{labels[localeID]["HOME"]}</Link>
                        <Link to={localeUrls[localeID]["STORES"]}>{labels[localeID]["STORES"]}</Link>
                        <Link to={localeUrls[localeID]["EVENTS"]}>{labels[localeID]["EVENTS"]}</Link>
                        <Link to={localeUrls[localeID]["NEWS"]}>{labels[localeID]["NEWS"]}</Link>
                        <Link to={localeUrls[localeID]["INFO"]}>{labels[localeID]["INFORMATION"]}</Link>
                        <Link to={localeUrls[localeID]["SELLERS"]}>{labels[localeID]["FOR_SELLERS"]}</Link>
                    </div>
                    <div>
                        <h5>{labels[localeID]["STORES"]}</h5>
                        <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_F"]}>{labels[localeID]["STORES_F"]}</AnchorLink>
                        <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_E"]}>{labels[localeID]["STORES_E"]}</AnchorLink>
                        <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_G"]}>{labels[localeID]["STORES_G"]}</AnchorLink>
                        <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_C"]}>{labels[localeID]["STORES_C"]}</AnchorLink>
                    </div>
                    <div className="ohCon">
                        <h5>{labels[localeID]["OPHOURS"]}</h5>
                        <a>
                            <span>{labels[localeID]["MOFRI"]}</span><br/>
                            <span className="oh">8:00 - 19:00</span>
                        </a>
                        <a>
                            <span>{labels[localeID]["SATR"]}</span><br/>
                            <span className="oh">8:00 - 16:00</span>
                        </a>
                        <a>
                            <span>{labels[localeID]["SUND"]}</span><br/>
                            <span className="oh">{labels[localeID]["CLOSED"]}</span>
                        </a>
                        <ArrowLink className="l" color="#ED6E47" to={localeUrls[localeID]["INFO"]}>{labels[localeID]["DETOPHOURS"]}</ArrowLink>
                    </div>
                </div>
            </footer>
            <aside className="bottom">
                <div className='content fw'>
                    <span>© {new Date().getFullYear()} Fresh Market - {labels[localeID]["ALLRIGHTS"]}.</span>
                    <div>
                        <span className="center">Designed and built by <a><img src={bLogo} style={{marginLeft: "5px"}}/></a></span>
                        <span className="center">Content by <a><img src={dLogo} style={{marginLeft: "5px"}}/></a></span>
                    </div>
                    <span>
                        <Link to='/gdpr'>{labels[localeID]["MRULES"]}</Link>
                        <Link to='/gdpr'>GDPR</Link>
                    </span>
                </div>
            </aside>
        </>    
    )
}

export default Footer;
