import React, { useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sliderSettings } from '../const/attr'
import { labels, localeUrls } from '../langs/langs'
import { isNative } from "../functions/functions";

import wavy from '../images/wavy2.svg'

const PartnersSlider = ({ className, localeID }) => {

    const slider = useRef();

    const data = useStaticQuery(graphql`
        query PertnersSliderQuery {
            skPosts: allWpPost(
                filter: {categories: {nodes: {elemMatch: {slug: {eq: "prevadzky"}}}}, locale: {locale: {eq: "sk_SK"}}}
            ) {
                nodes {
                    acfStoresData {
                        sliderlogo {
                            ...ImgQueryData
                        }
                    }
                    slug
                }
            }
            enPosts: allWpPost(
                filter: {categories: {nodes: {elemMatch: {slug: {eq: "stores"}}}}, locale: {locale: {eq: "en_US"}}}
            ) {
                nodes {
                    acfStoresData {
                        sliderlogo {
                            ...ImgQueryData
                        }
                    }
                    slug
                }
            }
        }
    `)

    const logos = isNative(localeID) ? data.skPosts.nodes : data.enPosts.nodes;

    return (
        <div id='partnSlider' className={'infSlider ' + className}>
            {className == 'gray' && <img src={wavy}/>}
            <Slider {...sliderSettings} ref={slider}>
                {!!logos.length && logos.map((item, index)=>{
                        return (!!item?.acfStoresData?.sliderlogo?.localFile ?
                            <Link key={index} to={localeUrls[localeID]["STORES"] + '/' + item.slug}><GatsbyImage image={item?.acfStoresData?.sliderlogo?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' /></Link> :
                            null
                        )
                    })}
            </Slider>
        </div>
    )
}


export default PartnersSlider;
