import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

import CookieConsent, { Cookies } from "react-cookie-consent";

import Nav from "./Nav";
import Footer from "./Footer";

import { isNative } from "../functions/functions";

const Layout = ({ children, localeID='sk_SK', location, navShadow=false, translatedSlug }) => {
    const {
        wp: {
            generalSettings: { title },
        },
    } = useStaticQuery(graphql`
        query LayoutQuery {
            wp {
                generalSettings {
                    title
                    description
                }
            }
        }
    `)

    const gdprAccept = () => {
        Cookies.set('gatsby-gdpr-google-analytics', true);
        Cookies.set('gatsby-gdpr-google-tagmanager', true);
    }

    const [showCookieBar, setShowCookieBar] = useState(true);

    return (
        <>
            {showCookieBar && <CookieConsent
                location="bottom"
                buttonText={isNative(localeID) ? 'Prijímam' : 'Accept'}
                style={{ background:"#FFF", color:"#000" }}
                buttonStyle={{ background:"#ED6E47", color: "#FFF", fontSize: "16px", padding:"12px" }}
                onAccept={gdprAccept}
            >
                <span>{
                    isNative(localeID) ? 
                    'Tento web využíva súbory cookies na zlepšenie služieb pre Vás.' :
                    'This website uses cookies to enhance the user experience.'
                }</span><span className="fillFlex"/><span onClick={()=>setShowCookieBar(false)}>Decline</span>
            </CookieConsent>}
            <Nav localeID={localeID} location={location} shadow={navShadow} currentPath={location?.pathname} translatedSlug={translatedSlug}/>
            <main>{children}</main>
            <Footer localeID={localeID}/>
        </>
    )
}

export default Layout
