import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { transitionLink } from '../const/attr'

import { labels, localeUrls } from '../langs/langs'
import { isBrowser } from '../functions/functions'

import HeaderHours from './HeaderHours';

import logo from '../images/logo.svg';
import fb from '../images/fb.svg';
import ig from '../images/ig.svg';
import logoGr from '../images/logoGroceries.svg';
import logoCu from '../images/logoCuisines.svg';
import logoBa from '../images/logoBars.svg';
import logoMa from '../images/logoMarket.svg';


function Nav ({ localeID, location, shadow, currentPath, translatedSlug }) {

    const [navOpened, setNavOpened] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => window.scrollY > 0 ? setScrolled(true) : setScrolled(false)

    useEffect(()=>{
        if (isBrowser()) {
            window.addEventListener(`scroll`, handleScroll)
            return ()=>{
                window.removeEventListener(`scroll`, handleScroll)
            }
        }
    },[])

    return (
        <header className={'nav' + (navOpened ? ' opened' : '') + (scrolled ? ' scrolled' : '') + (shadow ? ' shadow' : '')}>
            <div className="content fw" id="headerHours">
                <HeaderHours localeID={localeID} location={location} currentPath={currentPath} translatedSlug={translatedSlug}/>
            </div>
            <div className="content fw">
                <div>
                    <AniLink {...transitionLink} id="logoCon" to={localeUrls[localeID]["HOME"]}>
                        <img src={logo} />
                    </AniLink>
                    <div id="menuButton" onClick={()=>setNavOpened(p=>!p)} className={navOpened ? 'clicked' : ''}>
                        <div>
                            <div className="bar f"></div>
                            <div className="bar s"></div>
                            <div className="bar t"></div>
                        </div>
                    </div>
                </div>
                <nav>
                    <div>
                        <DropDown to={localeUrls[localeID]["STORES"]} title={labels[localeID]["STORES"]} close={!navOpened}>
                            <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_F"]}><img src={logoMa}/>{labels[localeID]["STORES_F"]}</AnchorLink>
                            <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_E"]}><img src={logoCu}/>{labels[localeID]["STORES_E"]}</AnchorLink>
                            <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_G"]}><img src={logoGr}/>{labels[localeID]["STORES_G"]}</AnchorLink>
                            <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_C"]}><img src={logoBa}/>{labels[localeID]["STORES_C"]}</AnchorLink>
                        </DropDown>
                        <AniLink {...transitionLink} to={localeUrls[localeID]["EVENTS"]}>{labels[localeID]["EVENTS"]}</AniLink>
                        <AniLink {...transitionLink} to={localeUrls[localeID]["NEWS"]}>{labels[localeID]["NEWS"]}</AniLink>
                        <AniLink {...transitionLink} to={localeUrls[localeID]["INFO"]}>{labels[localeID]["INFORMATION"]}</AniLink>
                        <AniLink {...transitionLink} to={localeUrls[localeID]["SELLERS"]}>{labels[localeID]["FOR_SELLERS"]}</AniLink>
                    </div>
                    <div>
                        <AnchorLink to={localeUrls[localeID]["STORES"] + "#search"}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18 16.5932L12.8809 11.4741C13.7868 10.2746 14.324 8.78103 14.324 7.162C14.324 3.20654 11.1175 0 7.162 0C3.20654 0 0 3.20654 0 7.162C0 11.1175 3.20654 14.324 7.162 14.324C8.78103 14.324 10.2746 13.7868 11.4741 12.8809L16.5932 18L18 16.5932ZM12.3346 7.162C12.3346 10.0187 10.0187 12.3346 7.162 12.3346C4.30527 12.3346 1.98944 10.0187 1.98944 7.162C1.98944 4.30527 4.30527 1.98944 7.162 1.98944C10.0187 1.98944 12.3346 4.30527 12.3346 7.162Z" fill="black"/>
                            </svg>
                        </AnchorLink>
                        <a href="https://goo.gl/maps/a8yYwdtxWSW8ta9J7" target="_blank" className="cta">{labels[localeID]["VISIT_US"]}</a>
                        <div id="socNet" className="center">
                            <a target="_blank" href="https://www.facebook.com/FarmarskaTrznica/"><img src={fb}/></a>
                            <a target="_blank" href="https://www.instagram.com/freshmarketba/"><img src={ig}/></a>
                        </div>
                    </div>
                </nav>
            </div>
        </header>   
    )
}

export const DropDown = ({ children, title, close, to, selected, error, clickable }) => {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        if (e.currentTarget == e.target) {
            setOpened(p=>!p);
        }
    }

    useEffect(()=>{
        if (close) {
            setOpened(false)
        }
    },[close])

    const inner = <><span onClick={handleClick}>{title}</span> 
        <svg onClick={handleClick} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5L5 7.5L10 0.5H0Z" fill="#202020"/>
        </svg></>;

    return (
        <div className={"dropDown" + (opened ? ' opened' : '') + (selected ? ' selected' : '') + (error ? ' hasError' : '') + (clickable ? ' clickable' : '')}>
            {to ? <AniLink {...transitionLink} to={to}>{inner}</AniLink> : <span>{inner}</span>}
            <div>
                {children}
            </div>
        </div>
    ); 

}


export default Nav;
