import React from "react"
import { Link } from "gatsby"
import moment from 'moment'

import { isNative, isOpened, getKeyByValue, stripLastBackslash } from "../functions/functions";

import { labels, localeUrls } from '../langs/langs'

import ArrowLink from "./ArrowLink";

import clockG from "../images/clock.svg"
import clockR from "../images/clock-red.svg"

const HeaderHours = ({ localeID, currentPath, translatedSlug }) => {

    const OpeningHoursRow = ({ start, end, title, closed=false }) => {
        
        if (closed) return (
                <p>
                    <img src={clockR}/>
                    <span className="oh cl">{labels[localeID]["CLOSED"]}</span>
                </p>
            )
        
        console.log(start, end)
        const opened = isOpened(start, end, true);
        console.log(opened)
        return (
            <p>
                <img src={opened ? clockG : clockR}/>
                <span>{title}: <br/>
                <span className={"oh " + (opened ? 'op' : 'cl')}>{start} - {end}</span></span>
            </p>
        )
    }

    const day = moment().day();

    const translateUrlKey = getKeyByValue(localeUrls[localeID], stripLastBackslash(currentPath));

    return (
        <>
            <div>
                {(1 <= day && day <=5) && <OpeningHoursRow title={labels[localeID]["OPENED"]} start="8:00" end="19:00"/>}
                {(day == 6) && <OpeningHoursRow title={labels[localeID]["OPENED"]} start="8:00" end="16:00"/>}
                {(day == 7) && <OpeningHoursRow closed={true}/>}

                {(1 <= day && day <=5) && <OpeningHoursRow title={labels[localeID]["RESTAURANTS"]} start="10:30" end="19:00"/>}
                {(day == 6) && <OpeningHoursRow title={labels[localeID]["RESTAURANTS"]} start="10:30" end="16:00"/>}
                {(day == 7) && <OpeningHoursRow closed={true}/>}

                <p className="hideOnSmallScreen"><ArrowLink to={localeUrls[localeID]["INFO"]}>{labels[localeID]["DETOPHOURS"]}</ArrowLink></p>
            </div>
            <div id="langToggler">
                <p className={isNative(localeID) ? "bold" : ""}><Link to={!!translatedSlug?.["sk_SK"] ? translatedSlug["sk_SK"] : localeUrls["sk_SK"][!!translateUrlKey ? translateUrlKey : "HOME"]}>SK</Link></p>
                <p className={isNative(localeID) ? "" : "bold"}><Link to={!!translatedSlug?.["en_US"] ? translatedSlug["en_US"] : localeUrls["en_US"][!!translateUrlKey ? translateUrlKey : "HOME"]}>EN</Link></p>
            </div>
        </>
    );
}

export default HeaderHours
