import moment from 'moment'

export const isNative = (localeID) => localeID == 'sk_SK';

export const isBrowser = () => typeof window !== "undefined";

export const checkForSlug = (categories, categoryIn) => {
    for (const category of categories) {
        if (category.slug == categoryIn) {
            return true
        }
    }
    return false;
};

export const onTheFirstFloor = (position) => position[0] == "B";

export const isInTheFuture = (date) => moment(date).isAfter(moment());

export const isOpened = (start, end, test=false) => {
    if (test) {
        console.log(moment().format('YYYY-MM-DD') + ' ' + start)
        console.log(moment().format('YYYY-MM-DD') + ' ' + end)
        console.log(moment(moment().format('YYYY-MM-DD') + ' ' + start))
        console.log(moment(moment().format('YYYY-MM-DD') + ' ' + end))
    }
    return moment(moment().format('YYYY-MM-DD') + ' ' + start).isBefore(moment()) 
        && moment(moment().format('YYYY-MM-DD') + ' ' + end).isAfter(moment());
};

export const isItToday = (dayInWeek) => {
    const day = moment().day();
    if (dayInWeek === 'mofri') return 1 <= day && day <=5;
    if (dayInWeek === 'sa') return 6 == day;
    if (dayInWeek === 'su') return 7 == day;
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export const stripLastBackslash = (slug) => slug.slice(slug.length-1) === '/' ? slug.slice(0,slug.length-1) : slug;