import React from "react";

export const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: true,
    slidesToShow: 4,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1
            }
        },
    ]
};

export const sliderSettings1 = {
    ...sliderSettings,
    autoplay: false,
    infinite: false,
    arrows: true,
    nextArrow: <div/>,
    prevArrow: <div/>,
    responsive: null
}

export const transitionLink = {
    cover: true,
    bg: "#1D4B38 url(https://novy.freshmarket.sk/wp-content/uploads/2022/02/textureG.jpg) center/cover no-repeat",
    duration: 0.8
}