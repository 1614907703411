import React from "react";
import { Link } from "gatsby"

export const rSVG = (color) => <svg className='href' width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.5L5 5.5L1 9.5" stroke={color} strokeWidth="1.5"/>
    </svg>

export const lSVG = (color) => <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1L2 6L7 11" stroke={color} strokeWidth="1.5"/>
    </svg>

const ArrowLink = ({ to, children, className='', left=false, disabled=false, color="#1D4B3A", ...props }) => {
    return (
        <Link to={to} className={'aArow ' + className + (left ? ' left ' : ' ')} {...props} style={{color}}>
            {!left && <span>{children}</span>}
            {(!left && !disabled) && rSVG(color)}
            {(left && !disabled) && lSVG(color)}
            {left && <span>{children}</span>}
        </Link>
    )
}


export default ArrowLink;
